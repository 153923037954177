import { InputAdornment, TextField, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { getMallSlotsStreams, Streams } from 'api';
import { Header } from 'components';
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { TableInstance } from 'react-table';
import DateSlots from './DateSlots';
import MallFilter from './MallFilter';
import MallItems from './MallItems';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto 1fr',
    },
    container: {
      marginTop: '2.5rem',
      paddingInline: '2.5rem',
      paddingBlock: '1rem',
      backgroundColor: '#fff',
    },
    searchField: {
      minWidth: '45rem',
      // height: '2.5rem',
    },
    filterBtn: {
      minWidth: '14rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '2.5rem',
    },
    title: {
      color: '#57A946',
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  })
);

const MallSlots: React.FC = () => {
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const { isLoading: loadingStreams, data: mallSlotsStreams } = useQuery(
    ['mallSlotsStreams', date],
    () => getMallSlotsStreams(date),
    {
      onSuccess(data) {
        setSelectedItems(data.details.streams);
      },
    }
  );
  const tableInstance = useRef<TableInstance>(null);
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState<Streams[]>(mallSlotsStreams?.details?.streams || []);

  return (
    <Container className={classes.root} maxWidth={false}>
      <Header tableInstance={tableInstance} activeTab={'mall-slots'} />
      <Grid container className={classes.container}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h4" color="primary" className={classes.title}>
            Mall Overview
          </Typography>
          <TextField
            id="search-malls"
            placeholder="Search for mall"
            variant="outlined"
            size="small"
            onChange={(e) =>
              setSelectedItems(
                mallSlotsStreams?.details
                  ? mallSlotsStreams.details.streams.filter((item) =>
                      item.mallName.toLowerCase().includes(e.target.value.toLowerCase())
                    )
                  : []
              )
            }
            className={classes.searchField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <MallFilter options={mallSlotsStreams?.details?.streams || []} setSelectedMalls={setSelectedItems} />
        </Grid>
        <DateSlots date={date} setDate={setDate} />
        {loadingStreams ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%', marginTop: '1rem' }}>
            {[...Array(6)].map((_, i) => (
              <div key={i} style={{ display: 'flex', gap: '1rem', paddingBlock: '1rem' }}>
                <Skeleton variant="rect" width={24} height={24} style={{ borderRadius: '0.25rem' }} />
                <Skeleton variant="rect" width="60%" height={24} style={{ borderRadius: '0.25rem' }} />
              </div>
            ))}
          </div>
        ) : (
          <MallItems mallItems={selectedItems} />
        )}
      </Grid>
    </Container>
  );
};

export default MallSlots;
