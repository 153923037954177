import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SvgIcon, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Streams } from 'api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBtn: {
      minWidth: '14rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '2.5rem',
    },
    menu: {
      width: '14rem',
    },
    btnWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '0.5rem',
      alignItems: 'center',
    },
    btn: {
      minWidth: '9.25rem',
    },
  })
);

const MallFilter = ({
  options,
  setSelectedMalls,
}: {
  options: Streams[];
  setSelectedMalls: (mallI: Streams[]) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const menuOptions = options.map((option) => ({
    label: option.mallName,
    value: option.mallID.toString(),
  }));

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (value: string) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  const handleApplyChanges = () => {
    if (selectedItems.length) {
      setSelectedMalls(options.filter((option) => selectedItems.includes(option.mallID.toString())));
    } else {
      setSelectedMalls(options);
    }
    handleClose();
  };

  const handleClearFilter = () => {
    setSelectedItems([]);
    setSelectedMalls(options);
    handleClose();
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        size="medium"
        color="primary"
        className={classes.filterBtn}
        endIcon={
          <SvgIcon>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.71989 2.66634L7.04122 8.06701C7.23038 8.30356 7.33336 8.59746 7.33322 8.90034V13.333L8.66655 12.333V8.89967C8.66656 8.59702 8.76954 8.30338 8.95855 8.06701L13.2799 2.66634H2.72055H2.71989ZM2.71989 1.33301H13.2799C13.5311 1.33304 13.7771 1.40402 13.9897 1.53778C14.2024 1.67155 14.3729 1.86264 14.4816 2.08907C14.5904 2.31549 14.6329 2.56803 14.6044 2.81759C14.576 3.06715 14.4775 3.30359 14.3206 3.49967L9.99989 8.89967V12.333C9.99989 12.54 9.95169 12.7442 9.85912 12.9293C9.76655 13.1144 9.63215 13.2755 9.46655 13.3997L8.13322 14.3997C7.93513 14.5482 7.69958 14.6387 7.45296 14.661C7.20635 14.6832 6.95841 14.6363 6.73693 14.5256C6.51546 14.4148 6.3292 14.2446 6.19902 14.034C6.06884 13.8233 5.99989 13.5806 5.99989 13.333V8.89967L1.67922 3.49967C1.52223 3.30359 1.42382 3.06715 1.39533 2.81759C1.36683 2.56803 1.40941 2.31549 1.51817 2.08907C1.62692 1.86264 1.79742 1.67155 2.01003 1.53778C2.22263 1.40402 2.4687 1.33304 2.71989 1.33301V1.33301Z"
                fill="#343434"
              />
            </svg>
          </SvgIcon>
        }
      >
        Filter by location
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.menu,
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.value}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedItems.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                />
              }
              label={option.label}
            />
          </MenuItem>
        ))}

        <div className={classes.btnWrapper}>
          <Button onClick={handleApplyChanges} color="primary" variant="contained" className={classes.btn}>
            Apply
          </Button>
          <Button onClick={handleClearFilter} color="primary" variant="text" className={classes.btn}>
            Clear filter
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default MallFilter;
