import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ShopperOrder, Streams } from 'api';
import moment from 'moment';
import React, { useState } from 'react';
import { CustomSVGSeries, Hint, HorizontalGridLines, XAxis, XYPlot, YAxis } from 'react-vis';
import 'react-vis/dist/style.css';
import './rv-overide.css';
import { useHistory } from 'react-router';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBlock: '1.69rem',
      gap: '1.8rem',
      width: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem',
    },
    legend: {
      paddingTop: '1.8rem',
      borderTop: '1px solid #F4F4F4',
      display: 'flex',
      justifyContent: 'center',
      gap: '1.5rem',
    },
    indicator: {
      width: '1.25rem',
      height: '1.25rem',
      background: '#75CCF2',
      borderRadius: '50%',
    },
    footerText: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      gap: '0.5rem',
      color: '#000',
      fontSize: '0.875rem',
    },
    viewDetails: {
      fontSize: '0.875rem',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    legendText: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: '0.875rem',
    },
    expanded: {
      borderTop: '1px solid #F4F4F4',
      '&::before': {
        borderTop: '1px solid #F4F4F4',
        backgroundColor: 'transparent',
      },
    },
    cross: {
      display: 'inline-flex',
      padding: '1.5rem',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1.2rem',
      borderRadius: '0.5rem',
      background: '#fff',
      boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 6%)',
    },
    crossInner: {
      minWidth: '18rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#000',
      fontSize: '0.875rem',
      fontFamily: 'Roboto',
    },
    crossText: {
      fontWeight: 300,
      width: '100%',
      textAlign: 'right',
    },
    bolder: {
      fontWeight: 500,
      textWrap: 'nowrap',
    },
    crossImgWrapper: {
      display: 'flex',
      gap: '0.5rem',
      width: '100%',
      alignItems: 'center',
      '& p': {
        width: '100%',
      },
    },
    crossImg: {
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '50%',
    },
  })
);

export function isMallSlotRunningLate(order: ShopperOrder) {
  if (order.shoppingStatus.toLowerCase() === 'completed') return order;
  const deliveryDate = moment(order.slotStartTime, 'HH:mm');
  const now = moment();
  if (now.isSameOrAfter(deliveryDate)) {
    order.shoppingStatus = 'late';
  }

  return order;
}

function generateAxesValues(mall: Streams) {
  return mall.shoppers
    .map((shopper) => {
      return shopper.orders.map((order) => {
        return {
          meta: {
            ...order,
            stores: order.stores.map((store) => {
              const mallStore = mall.stores.find((s) => s.storeID === store.storeID);
              return {
                ...store,
                imageURL: mallStore?.imageURL,
                name: mallStore?.name,
              };
            }),
          },
          x: parseInt(order.startTime.split(':')[0]) + parseInt(order.startTime.split(':')[1]) / 60,
          y: mall.shoppers.findIndex((s) => s.shopperID === shopper.shopperID) + 1,
          size: order.stores.length * 60,
          customComponent: (row: any) => {
            const order = isMallSlotRunningLate(row.meta);
            return (
              <g className="inner-inner-component">
                <rect
                  x="0"
                  y="-15"
                  rx="3"
                  ry="3"
                  width={row.size}
                  height={30}
                  fill={
                    shopper.shopperStatus?.toLowerCase() !== 'active'
                      ? '#999999'
                      : ['progress', 'completed'].includes(order.shoppingStatus?.toLowerCase())
                      ? '#5BB980'
                      : order?.shoppingStatus?.toLowerCase() === 'late'
                      ? '#D32F2F'
                      : order?.shoppingStatus?.toLowerCase() === 'scheduled'
                      ? '#263B84'
                      : '#CCCCCC'
                  }
                  stroke="#fff"
                />
                <text
                  x={0}
                  y={0}
                  style={{
                    fontSize: '0.625rem',
                    fontWeight: 'bold',
                    fill:
                      row.meta.shoppingStatus?.toLowerCase() === 'completed' &&
                      shopper.shopperStatus?.toLowerCase() !== 'absent'
                        ? '#000'
                        : '#fff',
                  }}
                  dominantBaseline="middle"
                >
                  <tspan x="10" y="0">
                    {row.meta.mainOrderID}
                  </tspan>
                </text>
              </g>
            );
          },
        };
      });
    })
    .flat();
}

const startingPoints = [
  {
    x: 7,
    y: 0,
    size: 1,
    style: { stroke: 'transparent', fill: 'transparent' },
  },
  {
    x: 19,
    y: 0,
    size: 1,
    style: { stroke: 'transparent', fill: 'transparent' },
  },
];

const Collapsible: React.FC<{ mall: Streams }> = ({ mall }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState<any>(null);
  const { push } = useHistory();

  const getTotalOrders = (): number => {
    return mall.shoppers.reduce((total, shopper) => total + shopper.orders.length, 0);
  };

  const operatingHours = mall.stores[0]
    ? {
        start: moment(mall.stores[0].startTime, 'HH:mm').format('h A'),
        end: moment(mall.stores[0].endTime, 'HH:mm').format('h A'),
      }
    : {
        start: '8 AM',
        end: '6 PM',
      };

  const data = generateAxesValues(mall);
  const activeOrders = getTotalOrders();
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onClick={(e) => {
        !!activeOrders && setExpanded(!expanded);
      }}
      classes={{
        expanded: classes.expanded,
        root: classes.expanded,
      }}
    >
      <AccordionSummary
        aria-label="Expand"
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <FormControlLabel
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={<Checkbox color="primary" />}
          label=""
        />
        <div className={classes.header}>
          <Typography>{mall.mallName}</Typography>
          <Chip
            label={`Operating hours: ${operatingHours.start} - ${operatingHours.end}`}
            variant="outlined"
            style={{ borderColor: '#1A2026' }}
          />
          <Chip
            label={`Mall opened: ${operatingHours.end > moment().format('h A') ? 'yes' : 'no'}`}
            variant="outlined"
            style={{ borderColor: '#1A2026' }}
          />
          <Chip label={`Active orders: ${activeOrders}`} variant="outlined" style={{ borderColor: '#1A2026' }} />
          <Chip
            label={`Active shoppers: ${
              mall.shoppers.filter((shopper) => shopper.shopperStatus?.toLowerCase() === 'active').length
            }`}
            variant="outlined"
            style={{ borderColor: '#1A2026' }}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails onClick={(event) => event.stopPropagation()}>
        <div>
          <XYPlot
            height={Math.max(300, window.innerHeight * 0.4)}
            width={window.innerWidth * 0.85}
            margin={{ left: 100, top: 20 }}
          >
            <HorizontalGridLines
              style={{
                stroke: mall.shoppers.filter((shopper) => shopper.shopperStatus?.toLowerCase() === 'active').length
                  ? '#B9EC29'
                  : '#CCCCCC',
                strokeDasharray: mall.shoppers.filter((shopper) => shopper.shopperStatus?.toLowerCase() === 'active')
                  .length
                  ? 0
                  : '5, 5',
              }}
            />
            <XAxis
              tickFormat={(v) => (v >= 12 ? `${v === 12 ? 12 : v - 12}pm` : `${v}am`)}
              tickValues={[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]}
              style={{
                line: { stroke: '#75CCF2' },
                text: { stroke: 'none', fill: '#000', fontWeight: 300 },
              }}
            />
            <YAxis
              tickValues={mall.shoppers
                .map((shopper, index) => (shopper.orders.length > 0 ? index + 1 : null))
                .filter(Boolean)}
              hideLine
              tickFormat={(v) => `${mall.shoppers[v - 1].surname[0]}. ${mall.shoppers[v - 1].name}`}
              style={{
                text: { stroke: 'none', fill: '#000', fontWeight: 300, fontSize: 14 },
              }}
            />
            {!!data.length && (
              <CustomSVGSeries
                customComponent="square"
                onValueMouseOver={(value) => setHover(value)}
                data={[...data, ...startingPoints]}
              />
            )}
            {hover && (
              <Hint value={hover} style={{ fontSize: 14 }}>
                <div className={classes.cross} onMouseLeave={() => setHover(null)}>
                  {hover.meta.stores.map((store: any) => (
                    <div className={classes.crossInner} key={store.name}>
                      <div key={store.name} className={classes.crossImgWrapper}>
                        <img className={classes.crossImg} src={store.imageURL} alt={store.name} />
                        <Typography variant="body1" className={classes.bolder}>
                          {store.name}
                        </Typography>
                        <Typography variant="body1" className={classes.crossText}>
                          {store.quantity} items
                        </Typography>
                      </div>
                    </div>
                  ))}
                  <div className={classes.footerText}>
                    <Typography variant="body1" className={classes.bolder}>
                      Delivery Slot
                    </Typography>
                    <Typography variant="body1" className={classes.crossText}>
                      {moment(hover.meta.slotStartTime, 'HH:mm').format('ha')} -{' '}
                      {moment(hover.meta.slotEndTime, 'HH:mm').format('ha')}
                    </Typography>
                  </div>
                  {['completed', 'progress'].includes(hover.meta.shoppingStatus?.toLowerCase()) && (
                    <Button
                      variant="text"
                      color="primary"
                      className={classes.viewDetails}
                      onClick={() => {
                        push(`/orders/${hover.meta.orderID}`);
                      }}
                    >
                      View order details
                    </Button>
                  )}
                </div>
              </Hint>
            )}
          </XYPlot>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface Props {
  mallItems: Streams[];
}

const legends = [
  {
    indicator: '#75CCF2',
    label: 'Mall Operating Time',
  },
  {
    indicator: '#5BB980',
    label: 'Order In Progress',
  },
  {
    indicator: '#263B84',
    label: 'Scheduled Orders',
  },
  {
    indicator: '#B9EC29',
    label: 'Shopper Schedule',
  },
  {
    indicator: '#888',
    label: 'Shopper Absent',
  },
  {
    indicator: '#A50E0E',
    label: 'Shopper Running Late',
  },
];

const MallItems: React.FC<Props> = ({ mallItems }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.root}>
      <div className={classes.legend}>
        {legends.map((legend) => (
          <Typography className={classes.legendText} key={legend.label}>
            <span className={classes.indicator} style={{ background: legend.indicator }} />
            {legend.label}
          </Typography>
        ))}
      </div>
      {mallItems.map((mall, index) => (
        <Collapsible key={`${mall.mallName}-${index}`} mall={mall} />
      ))}
    </Grid>
  );
};

export default MallItems;
