import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '1.69rem',
      gap: '1.8rem',
    },
    btn: {
      display: 'flex',
      minWidth: '10.5625rem',
      padding: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.625rem',
      borderRadius: '0.25rem',
      border: '1px solid rgba(0, 0, 0, 0.20)',
      color: 'rgba(0, 0, 0, 0.20)',
    },
    selected: {
      color: '#000',
      border: '1px solid #000',
    },
  })
);

const generateDates = () => {
  const dates = [];
  const today = moment();

  for (let i = 1; i < 7; i++) {
    dates.push(moment(today).add(i, 'days').format('MM/DD/YYYY'));
  }

  return dates;
};

const DateSlots: React.FC<{ date: string; setDate: (date: string) => void }> = ({ date, setDate }) => {
  const classes = useStyles();
  const dates = generateDates();
  return (
    <Grid container direction="row" alignItems="center" className={classes.root}>
      <Button
        variant="outlined"
        className={`${classes.btn} ${date === new Date().toISOString().split('T')[0] && classes.selected}`}
        onClick={() => setDate(new Date().toISOString().split('T')[0])}
      >
        Today
      </Button>
      {dates.map((d) => (
        <Button
          variant="outlined"
          className={`${classes.btn} ${date === moment(d).format('YYYY-MM-DD') && classes.selected}`}
          onClick={() => setDate(moment(d).format('YYYY-MM-DD'))}
        >
          {d}
        </Button>
      ))}
    </Grid>
  );
};

export default DateSlots;
